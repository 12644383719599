import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import {
  AdminDrive,
  BasicProfile,
  BluekitArticleNumberMappings,
  Company,
  Console,
  ConsoleSet,
  ConsoleSetAssignment,
  CVKurve,
  DriveFamily,
  EditedDriveFamily,
  ExchangeProfile,
  FrameProfileFacade,
  NewAdminDrive,
  NewConsole,
  NewConsoleSet,
  NewConsoleSetAssignment,
  NewCVKurve,
  NewDriveFamily,
  NewFrameProfileFacade,
  NewSashProfileFacade,
  NewSeries,
  NewStrokeType,
  NewSystem,
  SashProfileFacade,
  Series,
  SeriesENMaxStrokeFacade,
  SeriesENMaxStrokeRoof,
  StrokeType,
  System,
  TestPhase,
  TestPhaseWithOptionalId,
  User,
} from './adminFacadeReducer';
import { AdminState, AdminThunkAction, AdminThunkDispatch } from './adminStore';
import {
  adminDeleteCall,
  fetchWithoutCalculationParameters,
  postAndStore,
  putAndStoreWithDelay,
  RequestTypes,
  standardHeaders,
} from '../httpClient';
import { DriveSeries, RangeOfApplication } from '../constants';
import {
  addConsoleRoof,
  addConsoleSetAssignmentFacade,
  addConsoleSetAssignmentRoof,
  addConsoleSetRoof,
  addCvCurveRoof,
  addSeriesRoof,
  updateAllSeriesRoof,
  UpdateConsoleRoof,
  updateConsoleRoof,
  UpdateConsoleSetAssignmentRoof,
  updateConsoleSetAssignmentRoof,
  updateConsoleSetAssignmentsRoof,
  updateConsoleSetsRoof,
  updateConsolesRoof,
  updateCvCurveRoof,
  updateCVKurvenRoof,
  updateSeriesRoof,
  updateTestPhasesRoof,
} from './adminRoofActions';
import { updateEditedTestPhase } from './editedTestPhaseActions';
import { TestPhaseRoof } from './adminRoofReducer';
import {
  storeDeleteTrainingCertificateOnSave,
  storeTrainingCertificateToUpload,
  updateEditedCompany,
  USER_MANAGEMENT_ACTIONS,
} from './user-management/editedUserAndCompanyActions';
import { showSnackBar } from '../globalUiStateActions';
import { Edit } from '../../types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

export interface UpdateSystemsFacade extends Action<'UPDATE_SYSTEMS_FACADE'> {
  systems: System[];
}

export interface UpdateSeriesENMaxStrokesFacade
  extends Action<'UPDATE_SERIES_EN_MAX_STROKES_FACADE'> {
  seriesENMaxStrokesFacade: SeriesENMaxStrokeFacade[];
}

export interface UpdateSeriesENMaxStrokesRoof
  extends Action<'UPDATE_SERIES_EN_MAX_STROKES_ROOF'> {
  seriesENMaxStrokesRoof: SeriesENMaxStrokeRoof[];
}

export interface UpdateSystemFacade extends Action<'UPDATE_SYSTEM_FACADE'> {
  system: System;
}

export interface AddSystemFacade extends Action<'ADD_SYSTEM_FACADE'> {
  system: System;
}

export interface AddSeriesFacade extends Action<'ADD_SERIES_FACADE'> {
  series: Series;
}

export interface AddFrameProfileFacade
  extends Action<'ADD_FRAME_PROFILE_FACADE'> {
  frameProfile: FrameProfileFacade;
}

export interface UpdateAllSeriesFacade
  extends Action<'UPDATE_ALL_SERIES_FACADE'> {
  series: Series[];
}

export interface UpdateSeriesFacade extends Action<'UPDATE_SERIES_FACADE'> {
  series: Series;
}

export interface UpdateFrameProfilesFacade
  extends Action<'UPDATE_FRAME_PROFILES_FACADE'> {
  frameProfiles: FrameProfileFacade[];
}

export interface UpdateSashProfilesFacade
  extends Action<'UPDATE_SASH_PROFILES_FACADE'> {
  sashProfiles: SashProfileFacade[];
}

export interface UpdateBasicProfiles extends Action<'UPDATE_BASIC_PROFILES'> {
  basicProfiles: BasicProfile[];
}

export interface UpdateDriveSeries extends Action<'UPDATE_DRIVE_SERIES'> {
  driveSeries: DriveSeries[];
}

export interface UpdateBasicProfile extends Action<'UPDATE_BASIC_PROFILE'> {
  basicProfile: BasicProfile;
}

export interface UpdateFrameProfileFacade
  extends Action<'UPDATE_FRAME_PROFILE_FACADE'> {
  frameProfile: FrameProfileFacade;
}

export interface UpdateSashProfileFacade
  extends Action<'UPDATE_SASH_PROFILE_FACADE'> {
  sashProfile: SashProfileFacade;
}

export interface AddSashProfile extends Action<'ADD_SASH_PROFILE'> {
  sashProfile: SashProfileFacade;
}

export interface AddBasicProfile extends Action<'ADD_BASIC_PROFILE'> {
  basicProfile: BasicProfile;
}

export interface AddExchangeProfile extends Action<'ADD_EXCHANGE_PROFILE'> {
  exchangeProfile: ExchangeProfile;
}

export interface UpdateExchangeProfiles
  extends Action<'UPDATE_EXCHANGE_PROFILES'> {
  exchangeProfiles: ExchangeProfile[];
}

export interface UpdateExchangeProfile
  extends Action<'UPDATE_EXCHANGE_PROFILE'> {
  exchangeProfile: ExchangeProfile;
}

export interface UpdateCVKurvenFacade
  extends Action<'UPDATE_CV_KURVEN_FACADE'> {
  cvKurven: CVKurve[];
}

export interface AddCvCurveFacade extends Action<'ADD_CV_CURVE_FACADE'> {
  cvCurve: CVKurve;
}

export interface UpdateCvCurveFacade extends Action<'UPDATE_CV_CURVE_FACADE'> {
  cvCurve: CVKurve;
}

export interface UpdateConsolesFacade extends Action<'UPDATE_CONSOLES_FACADE'> {
  consoles: Console[];
}

export interface UpdateConsoleFacade extends Action<'UPDATE_CONSOLE_FACADE'> {
  console: Console;
}

export interface AddConsoleFacade extends Action<'ADD_CONSOLE_FACADE'> {
  console: Console;
}

export interface AddAdminDrive extends Action<'ADD_ADMIN_DRIVE'> {
  drive: AdminDrive;
}

export interface UpdateAdminDrives extends Action<'UPDATE_ADMIN_DRIVES'> {
  drives: AdminDrive[];
}

export interface UpdateAdminDrive extends Action<'UPDATE_ADMIN_DRIVE'> {
  drive: AdminDrive;
}

export interface UpdateMaxStrokes extends Action<'UPDATE_MAX_STROKES'> {
  maxStrokes: SeriesENMaxStrokeFacade[];
}

export interface UpdateEditedDriveFamily
  extends Action<'UPDATE_EDITED_DRIVE_FAMILY'> {
  family: Edit<NewDriveFamily> | DriveFamily | undefined;
}

export interface UpdateEditedDrive extends Action<'UPDATE_EDITED_DRIVE'> {
  drive: Edit<NewAdminDrive> | Edit<AdminDrive> | undefined;
}

export interface UpdateEditedStrokesFacade
  extends Action<'UPDATE_EDITED_STROKES_FACADE'> {
  facadeStrokes: SeriesENMaxStrokeFacade[];
}

export interface UpdateEditedStrokesRoof
  extends Action<'UPDATE_EDITED_STROKES_ROOF'> {
  roofStrokes: SeriesENMaxStrokeRoof[];
}

export interface UpdateEditedStrokeType
  extends Action<'UPDATE_EDITED_STROKE_TYPE'> {
  strokeType:
    | (Edit<NewStrokeType | StrokeType> & { index: number | undefined })
    | undefined;
}

export interface AddDriveFamily extends Action<'ADD_DRIVE_FAMILY'> {
  family: DriveFamily;
}

export interface UpdateDriveFamilies extends Action<'UPDATE_DRIVE_FAMILIES'> {
  families: DriveFamily[];
}

export interface UpdateDriveFamily extends Action<'UPDATE_DRIVE_FAMILY'> {
  family: DriveFamily;
}

export interface UpdateConsoleSetsFacade
  extends Action<'UPDATE_CONSOLE_SETS_FACADE'> {
  consoleSets: ConsoleSet[];
}

export interface UpdateConsoleSetFacade
  extends Action<'UPDATE_CONSOLE_SET_FACADE'> {
  consoleSet: ConsoleSet;
}

export interface AddConsoleSetFacade extends Action<'ADD_CONSOLE_SET_FACADE'> {
  consoleSet: ConsoleSet;
}

export interface UpdateConsoleSetAssignmentsFacade
  extends Action<'UPDATE_CONSOLE_SET_ASSIGNMENTS_FACADE'> {
  consoleSetAssignments: ConsoleSetAssignment[];
}

export interface UpdateConsoleSetAssignmentFacade
  extends Action<'UPDATE_CONSOLE_SET_ASSIGNMENT_FACADE'> {
  consoleSetAssignment: ConsoleSetAssignment;
}

export interface AddConsoleSetAssignmentFacade
  extends Action<'ADD_CONSOLE_SET_ASSIGNMENT_FACADE'> {
  consoleSetAssignment: ConsoleSetAssignment;
}

export interface UpdateTestPhases extends Action<'UPDATE_TEST_PHASES'> {
  testPhases: TestPhase[];
}

export interface UpdateTestPhase extends Action<'UPDATE_TEST_PHASE'> {
  testPhase: TestPhase;
}

export interface AddTestPhase extends Action<'ADD_TEST_PHASE'> {
  testPhase: TestPhase;
}

export interface UpdateUsers extends Action<'UPDATE_USERS'> {
  users: User[];
}

export interface UpdateUser extends Action<'UPDATE_SINGLE_USER'> {
  user: User;
}

export interface AddNewUser extends Action<'ADD_NEW_USER'> {
  user: User;
}

export interface AddNewCompany extends Action<'ADD_NEW_COMPANY'> {
  company: Company;
}

export interface UpdateCompanies extends Action<'UPDATE_COMPANIES'> {
  companies: Company[];
}

export interface UpdateCompany extends Action<'UPDATE_SINGLE_COMPANY'> {
  company: Company;
}

export interface UpdateBluekitArticleNumberMappingsAction
  extends Action<'UPDATE_BLUEKIT_ARTICLE_NUMBER_MAPPINGS'> {
  mappings: BluekitArticleNumberMappings[];
}

export type ADMIN_FACADE_ACTIONS =
  | UpdateSystemsFacade
  | UpdateSystemFacade
  | AddSystemFacade
  | UpdateAllSeriesFacade
  | UpdateSeriesFacade
  | AddSeriesFacade
  | UpdateFrameProfilesFacade
  | UpdateFrameProfileFacade
  | AddFrameProfileFacade
  | UpdateSashProfilesFacade
  | UpdateSashProfileFacade
  | AddSashProfile
  | UpdateBasicProfiles
  | UpdateBasicProfile
  | AddBasicProfile
  | UpdateExchangeProfiles
  | UpdateExchangeProfile
  | AddExchangeProfile
  | UpdateCVKurvenFacade
  | AddCvCurveFacade
  | UpdateCvCurveFacade
  | UpdateConsolesFacade
  | UpdateConsoleFacade
  | AddConsoleFacade
  | UpdateConsoleSetsFacade
  | UpdateConsoleSetFacade
  | AddConsoleSetFacade
  | UpdateConsoleSetAssignmentsFacade
  | UpdateConsoleSetAssignmentFacade
  | AddConsoleSetAssignmentFacade
  | UpdateTestPhases
  | UpdateTestPhase
  | AddTestPhase
  | UpdateUsers
  | UpdateUser
  | AddNewUser
  | UpdateCompanies
  | UpdateCompany
  | AddNewCompany
  | AddAdminDrive
  | UpdateAdminDrive
  | UpdateAdminDrives
  | AddDriveFamily
  | UpdateDriveFamily
  | UpdateDriveFamilies
  | UpdateEditedDriveFamily
  | UpdateEditedDrive
  | UpdateEditedStrokeType
  | UpdateSeriesENMaxStrokesFacade
  | UpdateEditedStrokesFacade
  | UpdateBluekitArticleNumberMappingsAction
  | UpdateDriveSeries;

export type AdminFacadeThunkAction = AdminThunkAction<
  | ADMIN_FACADE_ACTIONS
  | USER_MANAGEMENT_ACTIONS
  | UpdateConsoleSetAssignmentRoof
  | UpdateConsoleRoof
>;
export type AdminFacadeThunkDispatch = AdminThunkDispatch<
  | ADMIN_FACADE_ACTIONS
  | USER_MANAGEMENT_ACTIONS
  | UpdateConsoleSetAssignmentRoof
  | UpdateConsoleRoof
>;

export const updateAllSeriesFacade: ActionCreator<UpdateAllSeriesFacade> = (
  series: Series[],
): UpdateAllSeriesFacade => {
  return {
    type: 'UPDATE_ALL_SERIES_FACADE',
    series: series,
  };
};

export function updateSeriesFacade(series: Series): UpdateSeriesFacade {
  return {
    type: 'UPDATE_SERIES_FACADE',
    series: series,
  };
}

export function updateSystemsFacade(systems: System[]): UpdateSystemsFacade {
  return {
    type: 'UPDATE_SYSTEMS_FACADE',
    systems: systems,
  };
}

export function updateSeriesENMaxStrokesFacade(
  seriesENMaxStrokesFacade: SeriesENMaxStrokeFacade[],
): UpdateSeriesENMaxStrokesFacade {
  return {
    type: 'UPDATE_SERIES_EN_MAX_STROKES_FACADE',
    seriesENMaxStrokesFacade: seriesENMaxStrokesFacade,
  };
}

export function updateSystemFacade(system: System): UpdateSystemFacade {
  return {
    type: 'UPDATE_SYSTEM_FACADE',
    system: system,
  };
}

export const updateFrameProfilesFacade: ActionCreator<
  UpdateFrameProfilesFacade
> = (frameProfiles: FrameProfileFacade[]): UpdateFrameProfilesFacade => {
  return {
    type: 'UPDATE_FRAME_PROFILES_FACADE',
    frameProfiles: frameProfiles,
  };
};

export const updateFrameProfileFacade: ActionCreator<
  UpdateFrameProfileFacade
> = (frameProfile: FrameProfileFacade): UpdateFrameProfileFacade => {
  return {
    type: 'UPDATE_FRAME_PROFILE_FACADE',
    frameProfile: frameProfile,
  };
};

export const updateBasicProfile: ActionCreator<UpdateBasicProfile> = (
  basicProfile: BasicProfile,
): UpdateBasicProfile => {
  return {
    type: 'UPDATE_BASIC_PROFILE',
    basicProfile: basicProfile,
  };
};

export function updateBasicProfiles(
  basicProfiles: BasicProfile[],
): UpdateBasicProfiles {
  return {
    type: 'UPDATE_BASIC_PROFILES',
    basicProfiles: basicProfiles,
  };
}

export function updateDriveSeries(
  driveSeries: DriveSeries[],
): UpdateDriveSeries {
  return {
    type: 'UPDATE_DRIVE_SERIES',
    driveSeries: driveSeries,
  };
}

export function updateSashProfilesFacade(
  sashProfiles: SashProfileFacade[],
): UpdateSashProfilesFacade {
  return {
    type: 'UPDATE_SASH_PROFILES_FACADE',
    sashProfiles: sashProfiles,
  };
}

export function updateSashProfileFacade(
  sashProfile: SashProfileFacade,
): UpdateSashProfileFacade {
  return {
    type: 'UPDATE_SASH_PROFILE_FACADE',
    sashProfile: sashProfile,
  };
}

export function addSystemFacade(system: System): AddSystemFacade {
  return { type: 'ADD_SYSTEM_FACADE', system: system };
}

export function addSeriesFacade(series: Series): AddSeriesFacade {
  return { type: 'ADD_SERIES_FACADE', series: series };
}

export function addFrameProfileFacade(
  frameProfile: FrameProfileFacade,
): AddFrameProfileFacade {
  return { type: 'ADD_FRAME_PROFILE_FACADE', frameProfile: frameProfile };
}

export const addSashProfile: ActionCreator<AddSashProfile> = (
  sashProfile: SashProfileFacade,
): AddSashProfile => {
  return { type: 'ADD_SASH_PROFILE', sashProfile: sashProfile };
};

export const addBasicProfile: ActionCreator<AddBasicProfile> = (
  basicProfile: BasicProfile,
): AddBasicProfile => {
  return { type: 'ADD_BASIC_PROFILE', basicProfile: basicProfile };
};

export const addExchangeProfile: ActionCreator<AddExchangeProfile> = (
  exchangeProfile: ExchangeProfile,
): AddExchangeProfile => {
  return { type: 'ADD_EXCHANGE_PROFILE', exchangeProfile: exchangeProfile };
};

export const updateExchangeProfiles: ActionCreator<UpdateExchangeProfiles> = (
  exchangeProfiles: ExchangeProfile[],
): UpdateExchangeProfiles => {
  return {
    type: 'UPDATE_EXCHANGE_PROFILES',
    exchangeProfiles: exchangeProfiles,
  };
};

export function addCvCurveFacade(cvCurve: CVKurve): AddCvCurveFacade {
  return { type: 'ADD_CV_CURVE_FACADE', cvCurve: cvCurve };
}

export const updateExchangeProfile: ActionCreator<UpdateExchangeProfile> = (
  exchangeProfile: ExchangeProfile,
): UpdateExchangeProfile => {
  return {
    type: 'UPDATE_EXCHANGE_PROFILE',
    exchangeProfile: exchangeProfile,
  };
};

export function updateCVKurvenFacade(
  cvKurven: CVKurve[],
): UpdateCVKurvenFacade {
  return {
    type: 'UPDATE_CV_KURVEN_FACADE',
    cvKurven: cvKurven,
  };
}

export function updateCvCurveFacade(cvCurve: CVKurve): UpdateCvCurveFacade {
  return {
    type: 'UPDATE_CV_CURVE_FACADE',
    cvCurve: cvCurve,
  };
}

export function updateConsolesFacade(
  consoles: Console[],
): UpdateConsolesFacade {
  return {
    type: 'UPDATE_CONSOLES_FACADE',
    consoles: consoles,
  };
}

export function updateConsoleFacade(console: Console): UpdateConsoleFacade {
  return {
    type: 'UPDATE_CONSOLE_FACADE',
    console: console,
  };
}

export function addConsoleFacade(newConsole: Console): AddConsoleFacade {
  return { type: 'ADD_CONSOLE_FACADE', console: newConsole };
}

export function updateAdminDrives(drives: AdminDrive[]): UpdateAdminDrives {
  return {
    type: 'UPDATE_ADMIN_DRIVES',
    drives: drives,
  };
}

export function updateAdminDrive(drive: AdminDrive): UpdateAdminDrive {
  return {
    type: 'UPDATE_ADMIN_DRIVE',
    drive: drive,
  };
}

export function addAdminDrive(newDrive: AdminDrive): AddAdminDrive {
  return { type: 'ADD_ADMIN_DRIVE', drive: newDrive };
}

export function updateDriveFamilies(
  families: DriveFamily[],
): UpdateDriveFamilies {
  return {
    type: 'UPDATE_DRIVE_FAMILIES',
    families: families,
  };
}

export function updateDriveFamily(family: DriveFamily): UpdateDriveFamily {
  return {
    type: 'UPDATE_DRIVE_FAMILY',
    family: family,
  };
}

export function updateEditedDriveFamily(
  family: EditedDriveFamily | undefined,
): UpdateEditedDriveFamily {
  return {
    type: 'UPDATE_EDITED_DRIVE_FAMILY',
    family: family,
  };
}

export function updateEditedDrive(
  drive: Edit<AdminDrive> | Edit<NewAdminDrive> | undefined,
): UpdateEditedDrive {
  return {
    type: 'UPDATE_EDITED_DRIVE',
    drive: drive,
  };
}

export function updateEditedSeriesENMaxStrokesFacade(
  facadeStrokes: SeriesENMaxStrokeFacade[],
): UpdateEditedStrokesFacade {
  return {
    type: 'UPDATE_EDITED_STROKES_FACADE',
    facadeStrokes: facadeStrokes,
  };
}

export function updateEditedStrokeType(
  strokeType:
    | (Edit<NewStrokeType | StrokeType> & { index: number | undefined })
    | undefined,
): UpdateEditedStrokeType {
  return {
    type: 'UPDATE_EDITED_STROKE_TYPE',
    strokeType: strokeType,
  };
}

export function addDriveFamily(newFamily: DriveFamily): AddDriveFamily {
  return { type: 'ADD_DRIVE_FAMILY', family: newFamily };
}

export function updateConsoleSetsFacade(
  consoleSets: ConsoleSet[],
): UpdateConsoleSetsFacade {
  return {
    type: 'UPDATE_CONSOLE_SETS_FACADE',
    consoleSets: consoleSets,
  };
}

export function updateConsoleSetFacade(
  consoleSet: ConsoleSet,
): UpdateConsoleSetFacade {
  return {
    type: 'UPDATE_CONSOLE_SET_FACADE',
    consoleSet: consoleSet,
  };
}

export function updateConsoleSetAssignmentFacade(
  consoleSetAssignment: ConsoleSetAssignment,
): UpdateConsoleSetAssignmentFacade {
  return {
    type: 'UPDATE_CONSOLE_SET_ASSIGNMENT_FACADE',
    consoleSetAssignment: consoleSetAssignment,
  };
}

export function addConsoleSetFacade(
  newConsoleSet: ConsoleSet,
): AddConsoleSetFacade {
  return { type: 'ADD_CONSOLE_SET_FACADE', consoleSet: newConsoleSet };
}

export function updateConsoleSetAssignmentsFacade(
  consoleSetAssignments: ConsoleSetAssignment[],
): UpdateConsoleSetAssignmentsFacade {
  return {
    type: 'UPDATE_CONSOLE_SET_ASSIGNMENTS_FACADE',
    consoleSetAssignments: consoleSetAssignments,
  };
}

export function updateTestPhases(testPhases: TestPhase[]): UpdateTestPhases {
  return {
    type: 'UPDATE_TEST_PHASES',
    testPhases: testPhases,
  };
}

export function updateTestPhase(testPhase: TestPhase): UpdateTestPhase {
  return {
    type: 'UPDATE_TEST_PHASE',
    testPhase: testPhase,
  };
}

export function addTestPhase(testPhase: TestPhase): AddTestPhase {
  return { type: 'ADD_TEST_PHASE', testPhase: testPhase };
}

export function updateUsers(users: User[]): UpdateUsers {
  return {
    type: 'UPDATE_USERS',
    users: users,
  };
}

export function updateSingleUser(user: User): UpdateUser {
  return {
    type: 'UPDATE_SINGLE_USER',
    user: user,
  };
}

export function updateBluekitArticleNumberMappings(
  mappings: BluekitArticleNumberMappings[],
): UpdateBluekitArticleNumberMappingsAction {
  return {
    type: 'UPDATE_BLUEKIT_ARTICLE_NUMBER_MAPPINGS',
    mappings: mappings,
  };
}

export function addNewUser(newUser: User): AddNewUser {
  return { type: 'ADD_NEW_USER', user: newUser };
}

export function updateCompanies(companies: Company[]): UpdateCompanies {
  return {
    type: 'UPDATE_COMPANIES',
    companies: companies,
  };
}

export function updateSingleCompany(company: Company): UpdateCompany {
  return {
    type: 'UPDATE_SINGLE_COMPANY',
    company: company,
  };
}

export function addNewCompany(newCompany: Company): AddNewCompany {
  return { type: 'ADD_NEW_COMPANY', company: newCompany };
}

export function getSeriesENMaxStrokesFacade(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const seriesENMaxStrokesFacadeResult =
      await fetchSeriesENMaxStrokesFacade();
    dispatch(updateSeriesENMaxStrokesFacade(seriesENMaxStrokesFacadeResult));
  };
}

export function getSystemsFacade(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const systemsResult = await fetchSystems(RangeOfApplication.FACADE);
    dispatch(updateSystemsFacade(systemsResult));
  };
}

export function getAllSeries(
  rangeOfApplication: RangeOfApplication,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const seriesResult = await fetchAllSeries(rangeOfApplication);
    dispatch(
      rangeOfApplication === RangeOfApplication.FACADE
        ? updateAllSeriesFacade(seriesResult)
        : updateAllSeriesRoof(seriesResult),
    );
  };
}

export function getFrameProfiles(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const frameProfileResult = await fetchFrameProfiles();
    dispatch(updateFrameProfilesFacade(frameProfileResult));
  };
}

export function getSashProfiles(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const sashProfileResult = await fetchSashProfiles();
    dispatch(updateSashProfilesFacade(sashProfileResult));
  };
}

export function getDriveSeries(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const result = await fetchDriveSeries();
    dispatch(updateDriveSeries(result));
  };
}

export function getBasicProfiles(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const basicProfileResult = await fetchBasicProfiles();
    dispatch(updateBasicProfiles(basicProfileResult));
  };
}

export function getExchangeProfiles(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const exchangeProfiles = await fetchExchangeProfiles();
    dispatch(updateExchangeProfiles(exchangeProfiles));
  };
}

export function getCVKurven(
  rangeOfApplication: RangeOfApplication,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const cvKurven = await fetchCVKurven(rangeOfApplication);
    dispatch(
      rangeOfApplication === RangeOfApplication.FACADE
        ? updateCVKurvenFacade(cvKurven)
        : updateCVKurvenRoof(cvKurven),
    );
  };
}

export async function fetchCVKurven(
  rangeOfApplication: RangeOfApplication,
): Promise<CVKurve[]> {
  return fetchWithoutCalculationParameters<CVKurve>(
    '/api/admin/cvKurve?rangeOfApplication=' + rangeOfApplication,
  );
}

export async function fetchDriveSeries(): Promise<DriveSeries[]> {
  return fetchWithoutCalculationParameters('/api/admin/driveSeries')!;
}

export async function fetchBasicProfiles(): Promise<BasicProfile[]> {
  return fetchWithoutCalculationParameters('/api/admin/basicProfiles')!;
}

export async function fetchExchangeProfiles(): Promise<ExchangeProfile[]> {
  return fetchWithoutCalculationParameters('/api/admin/exchangeProfiles')!;
}

export async function fetchSashProfiles(): Promise<SashProfileFacade[]> {
  return fetchWithoutCalculationParameters('/api/admin/sashProfilesFacade')!;
}

export async function fetchFrameProfiles(): Promise<FrameProfileFacade[]> {
  return fetchWithoutCalculationParameters('/api/admin/frameProfilesFacade')!;
}

export async function fetchSeriesENMaxStrokesFacade(): Promise<
  SeriesENMaxStrokeFacade[]
> {
  return fetchWithoutCalculationParameters('/api/admin/maxStrokesFacade');
}

export async function fetchSeriesENMaxStrokesRoof(): Promise<
  SeriesENMaxStrokeRoof[]
> {
  return fetchWithoutCalculationParameters('/api/admin/maxStrokesRoof');
}

export async function fetchSashProfilesRoof(): Promise<SashProfileFacade[]> {
  return fetchWithoutCalculationParameters('/api/admin/sashProfilesRoof');
}

export async function fetchFrameProfilesRoof(): Promise<FrameProfileFacade[]> {
  return fetchWithoutCalculationParameters('/api/admin/frameProfilesRoof');
}

export async function fetchAllSeries(
  rangeOfApplication: RangeOfApplication,
): Promise<Series[]> {
  return fetchWithoutCalculationParameters(
    '/api/admin/series?rangeOfApplication=' + rangeOfApplication,
  );
}

export async function fetchSystems(
  dbType: RangeOfApplication,
): Promise<System[]> {
  return fetchWithoutCalculationParameters(
    '/api/admin/systems?rangeOfApplication=' + dbType,
  );
}

export async function fetchConsoles(
  rangeOfApplication: RangeOfApplication,
): Promise<Console[]> {
  return fetchWithoutCalculationParameters(
    '/api/admin/consoles?rangeOfApplication=' + rangeOfApplication,
  );
}

export async function fetchAdminDrives(): Promise<AdminDrive[]> {
  return fetchWithoutCalculationParameters('/api/admin/drives');
}

export async function fetchDriveFamilies(): Promise<DriveFamily[]> {
  return fetchWithoutCalculationParameters('/api/admin/driveFamilies');
}

export async function fetchConsolesets(
  rangeOfApplication: RangeOfApplication,
): Promise<ConsoleSet[]> {
  return fetchWithoutCalculationParameters(
    '/api/admin/consoleSet?rangeOfApplication=' + rangeOfApplication,
  );
}

export async function fetchConsolesetAssignments(
  rangeOfApplication: RangeOfApplication,
): Promise<ConsoleSetAssignment[]> {
  return fetchWithoutCalculationParameters(
    '/api/admin/consoleSetAssignment?rangeOfApplication=' + rangeOfApplication,
  );
}

export function getConsoles(
  rangeOfApplication: RangeOfApplication,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const consolesResponse = await fetchConsoles(rangeOfApplication);
    if (consolesResponse) {
      dispatch(
        rangeOfApplication === RangeOfApplication.FACADE
          ? updateConsolesFacade(consolesResponse)
          : updateConsolesRoof(consolesResponse),
      );
    }
  };
}

export function getAdminDrives(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const drives = await fetchAdminDrives();
    if (drives) {
      dispatch(updateAdminDrives(drives));
    }
  };
}

export function getDriveFamilies(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const families = await fetchDriveFamilies();
    if (families) {
      dispatch(updateDriveFamilies(families));
    }
  };
}

export function getConsoleSets(
  rangeOfApplication: RangeOfApplication,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const consoleSetsResponse = await fetchConsolesets(rangeOfApplication);
    dispatch(
      rangeOfApplication === RangeOfApplication.FACADE
        ? updateConsoleSetsFacade(consoleSetsResponse)
        : updateConsoleSetsRoof(consoleSetsResponse),
    );
  };
}

export function getConsoleSetAssignments(
  rangeOfApplication: RangeOfApplication,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const consoleSetAssignmentResponse = await fetchConsolesetAssignments(
      rangeOfApplication,
    );
    dispatch(
      rangeOfApplication === RangeOfApplication.FACADE
        ? updateConsoleSetAssignmentsFacade(consoleSetAssignmentResponse)
        : updateConsoleSetAssignmentsRoof(consoleSetAssignmentResponse),
    );
  };
}

export function getTestPhases(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    fetchTestPhases()
      .then(testPhaseResponse => dispatch(updateTestPhases(testPhaseResponse)))
      .catch(e => window.logger.error(e));
  };
}

export async function fetchTestPhases(): Promise<TestPhase[]> {
  return fetchWithoutCalculationParameters('/api/admin/testPhases');
}

export function getTestPhasesRoof(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    fetchTestPhasesRoof()
      .then(testPhaseResponse =>
        dispatch(updateTestPhasesRoof(testPhaseResponse)),
      )
      .catch(e => window.logger.error(e));
  };
}

export async function fetchTestPhasesRoof(): Promise<TestPhaseRoof[]> {
  return fetchWithoutCalculationParameters('/api/admin/testPhasesRoof');
}

export async function fetchCompanies(): Promise<Company[]> {
  return fetchWithoutCalculationParameters('/api/admin/companies');
}

export async function fetchBluekitArticleNumberMappings(): Promise<
  BluekitArticleNumberMappings[]
> {
  return fetchWithoutCalculationParameters(
    '/api/admin/companies/bluekitArticleNumberMappings',
  );
}

export function getCompanies(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const companyResponse = await fetchCompanies();
    dispatch(updateCompanies(companyResponse));
  };
}

export function getBluekitArticleNumberMappings(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const mappings = await fetchBluekitArticleNumberMappings();
    dispatch(updateBluekitArticleNumberMappings(mappings));
  };
}

export function saveBluekitArticleNumberMappings(
  changedElement: BluekitArticleNumberMappings[],
): AdminFacadeThunkAction {
  return async (dispatch: AdminFacadeThunkDispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.BLUEKIT_ARTICLE_NUMBER_MAPPINGS,
      async (mappings: BluekitArticleNumberMappings[] | undefined) => {
        if (mappings === undefined) {
          return;
        }
        await dispatch(
          showSnackBar('Artikelnummern gespeichert', undefined, false),
        );
        await dispatch(getCompanies());

        await dispatch(updateBluekitArticleNumberMappings(mappings));
        await dispatch(getCompanies());
      },
      changedElement,
    );
  };
}

export function saveCompany(companyToUpdate: Company): AdminFacadeThunkAction {
  return async (dispatch: AdminFacadeThunkDispatch): Promise<void> => {
    if (!companyToUpdate.id) {
      throw new Error('id must be provided');
    }
    await putAndStoreWithDelay(
      RequestTypes.COMPANIES,
      async (company: Company | undefined) => {
        if (company) {
          await dispatch(updateSingleCompany(company));
          await dispatch(deletedTrainingCertificate());
          await dispatch(uploadCertificates());
          await dispatch(getCompanies());
          await dispatch(getBluekitArticleNumberMappings());
        }
      },
      {
        ...companyToUpdate,
        parentCompanyId: companyToUpdate.parentCompany?.id || null,
      },
      companyToUpdate.id,
    );
  };
}

function uploadCertificates(): AdminFacadeThunkAction {
  return async (
    dispatch: AdminFacadeThunkDispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (getState().adminFacade.editedCompany?.id) {
      await dispatch(
        uploadTrainingCertificate(
          getState().adminFacade.editedCompany as Company,
          () => dispatch(storeTrainingCertificateToUpload(undefined)),
          state => state.adminFacade.trainingCertificateToUpload!,
        ),
      );
    }
  };
}

export function createCompany(): AdminFacadeThunkAction {
  return async (
    dispatch: AdminFacadeThunkDispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    await postAndStore(
      RequestTypes.COMPANIES,
      (createdCompany: Company | undefined) => {
        if (!createdCompany) {
          return;
        }
        dispatch(updateEditedCompany(createdCompany));
        dispatch(uploadCertificates());
        dispatch(addNewCompany(createdCompany));
      },
      {
        ...getState().adminFacade.editedCompany!,
        parentCompanyId:
          getState().adminFacade.editedCompany?.parentCompany?.id || null,
      },
    );
  };
}

export function uploadTrainingCertificate(
  company: Company,
  afterUploadCallback: () => void,
  certificateToUpload: (state: AdminState) => File,
): AdminFacadeThunkAction {
  return async (
    dispatch: AdminFacadeThunkDispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (certificateToUpload(getState())) {
      const formData = new FormData();
      formData.append('file', certificateToUpload(getState()));
      await standardHeaders().then(headers =>
        fetch(`/api/admin/companies/${company.id}/trainingCertificate`, {
          headers: { ...headers },
          method: 'POST',
          body: formData,
        }),
      );

      afterUploadCallback();
      dispatch(getCompanies());
    }
  };
}

function deletedTrainingCertificate(): AdminFacadeThunkAction {
  return async (
    dispatch: AdminFacadeThunkDispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (getState().adminFacade.deleteTrainingCertificateOnSave) {
      await adminDeleteCall({
        requestType: RequestTypes.TRAINING_CERTIFICATE,
        id: getState().adminFacade.editedCompany!.id!,
      });
      dispatch(storeDeleteTrainingCertificateOnSave(false));
    }
  };
}

export function getUsers(): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const userResponse = await fetchUsers();
    dispatch(updateUsers(userResponse));
  };
}

export async function fetchUsers(): Promise<User[]> {
  return fetchWithoutCalculationParameters('/api/admin/users');
}

export function getAllConsoleData(): AdminFacadeThunkAction {
  return async (dispatch: AdminFacadeThunkDispatch): Promise<void> => {
    dispatch(getConsoles(RangeOfApplication.FACADE));
    dispatch(getConsoles(RangeOfApplication.ROOF));
    dispatch(getConsoleSets(RangeOfApplication.FACADE));
    dispatch(getConsoleSets(RangeOfApplication.ROOF));
    dispatch(getConsoleSetAssignments(RangeOfApplication.FACADE));
    dispatch(getConsoleSetAssignments(RangeOfApplication.ROOF));
  };
}

export function changeConsole(changedConsole: Console): AdminFacadeThunkAction {
  return async (dispatch: AdminFacadeThunkDispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.CONSOLES,
      (updatedConsole: Console | undefined) => {
        if (updatedConsole) {
          dispatch(
            changedConsole.rangeOfApplication === RangeOfApplication.FACADE
              ? updateConsoleFacade(updatedConsole)
              : updateConsoleRoof(updatedConsole),
          );
          dispatch(getAllConsoleData());
        }
      },
      changedConsole,
      changedConsole.id!,
    );
  };
}

export function changeConsoleSet(
  changedConsoleSet: ConsoleSet,
): AdminFacadeThunkAction {
  return async (dispatch: AdminFacadeThunkDispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.CONSOLE_SET,
      (updatedConsoleSet: ConsoleSet | undefined) => {
        if (updatedConsoleSet) {
          dispatch(updateConsoleSetFacade(updatedConsoleSet));
          dispatch(getAllConsoleData());
        }
      },
      changedConsoleSet,
      changedConsoleSet.id!,
    );
  };
}

export function changeConsoleSetAssignment(
  changedConsoleSetAssignment: ConsoleSetAssignment,
): AdminFacadeThunkAction {
  return async (dispatch: AdminFacadeThunkDispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.CONSOLE_SET_ASSIGNMENT,
      (updatedAssignment: ConsoleSetAssignment | undefined) => {
        if (updatedAssignment) {
          dispatch(
            changedConsoleSetAssignment.rangeOfApplication ===
              RangeOfApplication.FACADE
              ? updateConsoleSetAssignmentFacade(updatedAssignment)
              : updateConsoleSetAssignmentRoof(updatedAssignment),
          );
          dispatch(getAllConsoleData());
        }
      },
      changedConsoleSetAssignment,
      changedConsoleSetAssignment.id!,
    );
  };
}

export function changeMaxStrokesFacade(
  changedMaxStrokes: SeriesENMaxStrokeFacade[],
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.MAX_STROKES_FACADE,
      (updatedMaxStrokes: SeriesENMaxStrokeFacade[] | undefined) => {
        if (updatedMaxStrokes) {
          dispatch(updateSeriesENMaxStrokesFacade(updatedMaxStrokes));
        }
      },
      changedMaxStrokes,
      -1,
    );
  };
}

export function changeAdminDrive(
  changedAdminDrive: AdminDrive,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.UPDATE_ADMIN_DRIVES,
      (updatedAdminDrive: AdminDrive | undefined) => {
        if (updatedAdminDrive) {
          dispatch(updateAdminDrive(updatedAdminDrive));
        }
      },
      changedAdminDrive,
      changedAdminDrive.id,
    );
  };
}

export function changeDriveFamily(
  changedDriveFamily: DriveFamily,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.UPDATE_DRIVE_FAMILY,
      (updatedDriveFamily: DriveFamily | undefined) => {
        if (updatedDriveFamily) {
          dispatch(updateDriveFamily(updatedDriveFamily));
        }
      },
      changedDriveFamily,
      changedDriveFamily.id,
    );
  };
}

export function changeCvCurve(newCvCurve: CVKurve): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.CV_KURVE,
      (updatedCvCurve: CVKurve | undefined) => {
        if (updatedCvCurve) {
          dispatch(
            newCvCurve.rangeOfApplication === RangeOfApplication.FACADE
              ? updateCvCurveFacade(updatedCvCurve)
              : updateCvCurveRoof(updatedCvCurve),
          );
        }
      },
      newCvCurve,
      newCvCurve.id,
    );
  };
}

export function changeSystem(newSystem: System): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.SYSTEM,
      (updatedSystem: System | undefined) => {
        if (!updatedSystem) {
          return;
        }
        dispatch(updateSystemFacade(updatedSystem));
      },
      newSystem,
      newSystem.id!,
    );
  };
}

export function changeSeries(
  changedSeries: Series,
  rangeOfApplication: RangeOfApplication,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.SERIES,
      (updatedSeries: Series | undefined) => {
        dispatch(
          rangeOfApplication === RangeOfApplication.FACADE
            ? updateSeriesFacade(updatedSeries!)
            : updateSeriesRoof(updatedSeries!),
        );
      },
      changedSeries,
      changedSeries.id,
    );
  };
}

export const changeBasicProfile: ActionCreator<AdminFacadeThunkAction> = (
  changedBasicProfile: BasicProfile,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.BASIC_PROFILE,
      (updatedBasicProfile: BasicProfile | undefined) => {
        dispatch(updateBasicProfile(updatedBasicProfile));
      },
      changedBasicProfile,
      changedBasicProfile.id,
    );
  };
};

export const changeExchangeProfile: ActionCreator<AdminFacadeThunkAction> = (
  changedExchangeProfile: ExchangeProfile,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.EXCHANGE_PROFILE,
      (updatedExchangeProfile: ExchangeProfile | undefined) => {
        dispatch(updateExchangeProfile(updatedExchangeProfile));
      },
      changedExchangeProfile,
      changedExchangeProfile.id,
    );
  };
};

export function changeFrameProfileFacade(
  changedFrameProfile: FrameProfileFacade,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.FRAME_PROFILE_FACADE,
      (updatedFrameProfile: FrameProfileFacade | undefined) => {
        dispatch(updateFrameProfileFacade(updatedFrameProfile));
      },
      changedFrameProfile,
      changedFrameProfile.id,
    );
  };
}

export function changeSashProfileFacade(
  changedSashProfile: SashProfileFacade,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.SASH_PROFILE_FACADE,
      (updatedSashProfile: SashProfileFacade | undefined) => {
        if (!updatedSashProfile) {
          return;
        }
        dispatch(updateSashProfileFacade(updatedSashProfile));
      },
      changedSashProfile,
      changedSashProfile.id,
    );
  };
}

export const createBasicProfile: ActionCreator<AdminFacadeThunkAction> = (
  newBasicProfile: BasicProfile,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.BASIC_PROFILE,
      (createdBasicProfile: BasicProfile | undefined) => {
        dispatch(addBasicProfile(createdBasicProfile));
      },
      newBasicProfile,
      newBasicProfile.id,
    );
  };
};

export const createExchangeProfile: ActionCreator<AdminFacadeThunkAction> = (
  newExchangeProfile: ExchangeProfile,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.EXCHANGE_PROFILE,
      (createdExchangeProfile: ExchangeProfile | undefined) => {
        dispatch(addExchangeProfile(createdExchangeProfile));
      },
      newExchangeProfile,
      newExchangeProfile.id,
    );
  };
};

export function createCvCurve(newCvCurve: NewCVKurve): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.CV_KURVE,
      (createdCvCurve: CVKurve | undefined) => {
        if (createdCvCurve) {
          dispatch(
            newCvCurve.rangeOfApplication === RangeOfApplication.FACADE
              ? addCvCurveFacade(createdCvCurve)
              : addCvCurveRoof(createdCvCurve),
          );
        }
      },
      newCvCurve,
    );
  };
}

export function createSashProfile(
  newSashProfil: NewSashProfileFacade,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.SASH_PROFILE_FACADE,
      (createdSashProfile: SashProfileFacade | undefined) => {
        dispatch(addSashProfile(createdSashProfile));
      },
      newSashProfil,
    );
  };
}

export function createFrameProfileFacade(
  newFrameProfile: NewFrameProfileFacade,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.FRAME_PROFILE_FACADE,
      (createdFrameProfile: FrameProfileFacade | undefined) => {
        if (!createdFrameProfile) {
          return;
        }
        dispatch(addFrameProfileFacade(createdFrameProfile));
      },
      newFrameProfile,
    );
  };
}

export function createSystem(newSystem: NewSystem): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.SYSTEM,
      (createdSystem: System | undefined) => {
        if (!createdSystem) {
          return;
        }
        dispatch(addSystemFacade(createdSystem));
      },
      newSystem,
    );
  };
}

export function createSeries(newSeries: NewSeries): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.SERIES,
      (createdSeries: Series | undefined) => {
        if (!createdSeries) {
          return;
        }
        dispatch(
          newSeries.rangeOfApplication === RangeOfApplication.FACADE
            ? addSeriesFacade(createdSeries)
            : addSeriesRoof(createdSeries),
        );
      },
      newSeries,
    );
  };
}

export function createConsoleSetAssignment(
  newCSA: NewConsoleSetAssignment,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.CONSOLE_SET_ASSIGNMENT,
      (createdConsoleSetAssignment: ConsoleSetAssignment | undefined) => {
        if (!createdConsoleSetAssignment) {
          return;
        }
        if (
          createdConsoleSetAssignment.rangeOfApplication ===
          RangeOfApplication.ROOF
        ) {
          dispatch(addConsoleSetAssignmentRoof(createdConsoleSetAssignment));
        } else {
          dispatch(addConsoleSetAssignmentFacade(createdConsoleSetAssignment));
        }
      },
      newCSA,
    );
  };
}

export function addConsoleSetAssignment(
  newConsoleSetAssignment: ConsoleSetAssignment,
): AddConsoleSetAssignmentFacade {
  return {
    type: 'ADD_CONSOLE_SET_ASSIGNMENT_FACADE',
    consoleSetAssignment: newConsoleSetAssignment,
  };
}

export function createConsole(newConsole: NewConsole): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.CONSOLES,
      (createdConsole: Console | undefined) => {
        if (createdConsole) {
          dispatch(
            newConsole.rangeOfApplication === RangeOfApplication.FACADE
              ? addConsoleFacade(createdConsole!)
              : addConsoleRoof(createdConsole),
          );
        }
      },
      newConsole,
    );
  };
}

export function createAdminDrive(
  newAdminDrive: NewAdminDrive,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.ADMIN_DRIVES,
      (createdAdminDrive: AdminDrive | undefined) => {
        if (createdAdminDrive) {
          dispatch(addAdminDrive(createdAdminDrive));
        }
      },
      newAdminDrive,
    );
  };
}

export function createDriveFamily(
  newDriveFamily: NewDriveFamily,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.DRIVE_FAMILIES,
      (createdDriveFamily: DriveFamily | undefined) => {
        if (createdDriveFamily) {
          dispatch(addDriveFamily(createdDriveFamily));
        }
      },
      newDriveFamily,
    );
  };
}

export function createConsoleSet(
  newConsoleSet: NewConsoleSet,
  rangeOfApplication: RangeOfApplication,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.CONSOLE_SET,
      (createdConsoleSet: ConsoleSet | undefined) => {
        dispatch(
          rangeOfApplication === RangeOfApplication.FACADE
            ? addConsoleSetFacade(createdConsoleSet!)
            : addConsoleSetRoof(createdConsoleSet!),
        );
      },
      newConsoleSet,
    );
  };
}

export function changeTestPhase(
  changedTestPhase: TestPhaseWithOptionalId,
): AdminFacadeThunkAction {
  return async (dispatch: AdminThunkDispatch<AnyAction>): Promise<void> => {
    if (!changedTestPhase.id) {
      throw new Error('Test phase id must be provided');
    }
    await putAndStoreWithDelay(
      RequestTypes.TEST_PHASES,
      (updatedTestPhase: TestPhase | undefined) => {
        if (updatedTestPhase) {
          dispatch(updateTestPhase(updatedTestPhase));
        }
      },
      changedTestPhase,
      changedTestPhase.id,
    );
  };
}

export function selectEditableTestPhase(
  testPhaseToSelect: TestPhase,
): AdminFacadeThunkAction {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateEditedTestPhase(testPhaseToSelect));
  };
}

export function createTestPhase(): AdminFacadeThunkAction {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    await postAndStore(
      RequestTypes.TEST_PHASES,
      (createdTestPhase: TestPhase | undefined) => {
        if (!createdTestPhase) {
          return;
        }
        dispatch(addTestPhase(createdTestPhase));
      },
      getState().adminFacade.editedTestPhase!,
    );
  };
}

export function copyTestPhaseFacade(
  id: number,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<AdminState, any, AnyAction>,
  ): Promise<void> => {
    postAndStore(
      RequestTypes.COPY_TEST_PHASE_FACADE,
      () => {
        dispatch(getTestPhases());
      },
      {},
      id,
    );
  };
}
