import React, { FC, useEffect, useState } from 'react';
import FacadeArea from './facade/FacadeArea';
import RoofArea from './roof/RoofArea';
import GeneralDataArea from './general-data-area/GeneralDataArea';
import { Route } from 'react-router';
import './Admin.scss';
import {
  getAdminDrives,
  getAllSeries,
  getBasicProfiles,
  getBluekitArticleNumberMappings,
  getCompanies,
  getConsoles,
  getConsoleSetAssignments,
  getConsoleSets,
  getCVKurven,
  getDriveFamilies,
  getDriveSeries,
  getExchangeProfiles,
  getFrameProfiles,
  getSashProfiles,
  getSeriesENMaxStrokesFacade,
  getSystemsFacade,
  getTestPhases,
  getTestPhasesRoof,
} from '../redux/admin/adminFacadeActions';
import { useDispatch, useSelector } from 'react-redux';
import { RangeOfApplication } from '../redux/constants';
import { IState } from '../redux/store';
import {
  getFrameProfilesRoof,
  getSashProfilesRoof,
  getSeriesENMaxStrokesRoof,
  getWindDeflectors,
} from '../redux/admin/adminRoofActions';
import { Navigate, Routes, useLocation, useNavigate } from 'react-router-dom';
import CompanyManagementArea from './companies-management/CompanyManagementArea';
import { LoggedInUser } from '../redux/authenticationReducer';
import { AdminThunkDispatch } from '../redux/admin/adminStore';
import { AnyAction } from 'redux';
import { ReactComponent as DHlogo } from '../../src/components/Header/dh-logo.svg';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { updateDisplaySnackBar } from '../redux/globalUiStateActions';
import { useIntl } from 'react-intl';

const SideNav: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const currentUser = useSelector<IState, LoggedInUser | undefined>(
    (state: IState) => state.authentication.currentUser,
  );
  useEffect(() => setCurrentPath(location.pathname), [location]);

  function isActive(pathPart: string): boolean {
    return !!location.pathname.startsWith(`/admin/${pathPart}`);
  }

  function selectedValue(): string | undefined {
    if (isActive('companies-management')) {
      return 'admin/companies-management/companies';
    }
    if (isActive('facade')) {
      return 'admin/facade/systems';
    }
    if (isActive('roof')) {
      return 'admin/roof/systems';
    }
    if (isActive('general-data')) {
      return 'admin/general-data/drives';
    }
  }

  return (
    <div className="admin__main-title">
      <select
        onChange={e => navigate(`/${e.target.value}`)}
        key={currentPath}
        value={selectedValue()}
      >
        {currentUser?.userAdmin && (
          <option value="admin/companies-management/companies">Firmen</option>
        )}
        {currentUser?.admin && (
          <>
            <option value="admin/facade/systems">Fassade</option>
            <option value="admin/roof/systems">Dach</option>
            <option value="admin/general-data/drives">Allgemein</option>
          </>
        )}
      </select>
    </div>
  );
};

const Admin: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const currentUser = useSelector<IState, LoggedInUser | undefined>(
    (state: IState) => state.authentication.currentUser,
  );

  const displaySnackBar = useSelector<IState, boolean>(
    state => state.globalUIState.displaySnackBar,
  );
  const snackBarMessage = useSelector<IState, string>(
    state => state.globalUIState.snackBarMessage,
  );
  const snackBarMessageText = useSelector<IState, string>(
    state => state.globalUIState.snackBarMessageText,
  );
  const showSuccessMessage = useSelector<IState, boolean>(
    state => state.globalUIState.showSuccessMessage,
  );

  const { formatMessage } = useIntl();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(updateDisplaySnackBar(false));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (currentUser) {
      if (currentUser.admin) {
        dispatch(getDriveSeries());
        dispatch(getSystemsFacade());
        dispatch(getAllSeries(RangeOfApplication.FACADE));
        dispatch(getAllSeries(RangeOfApplication.ROOF));
        dispatch(getFrameProfiles());
        dispatch(getSashProfiles());
        dispatch(getFrameProfilesRoof());
        dispatch(getSashProfilesRoof());
        dispatch(getBasicProfiles());
        dispatch(getExchangeProfiles());
        dispatch(getCVKurven(RangeOfApplication.FACADE));
        dispatch(getCVKurven(RangeOfApplication.ROOF));
        dispatch(getConsoles(RangeOfApplication.FACADE));
        dispatch(getConsoles(RangeOfApplication.ROOF));
        dispatch(getConsoleSets(RangeOfApplication.FACADE));
        dispatch(getConsoleSets(RangeOfApplication.ROOF));
        dispatch(getConsoleSetAssignments(RangeOfApplication.FACADE));
        dispatch(getConsoleSetAssignments(RangeOfApplication.ROOF));
        dispatch(getTestPhases());
        dispatch(getTestPhasesRoof());
        dispatch(getAdminDrives());
        dispatch(getDriveFamilies());
        dispatch(getSeriesENMaxStrokesFacade());
        dispatch(getSeriesENMaxStrokesRoof());
        dispatch(getWindDeflectors());
      }
      if (currentUser.userAdmin) {
        dispatch(getCompanies());
        dispatch(getBluekitArticleNumberMappings());
      }
    }
  }, [currentUser]);

  return (
    <>
      <div className="header">
        <div className="header__app-header">
          <DHlogo />
        </div>
      </div>
      <div className="admin">
        <SideNav />
        <Routes>
          <Route path="/facade/*" element={<FacadeArea />} />
          <Route path="/roof/*" element={<RoofArea />} />
          <Route path="/general-data/*" element={<GeneralDataArea />} />
          <Route
            path="/companies-management/*"
            element={<CompanyManagementArea />}
          />
          <Route
            path="/"
            element={
              <Navigate replace to="/admin/companies-management/companies" />
            }
          />
        </Routes>
        <div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={displaySnackBar}
            autoHideDuration={4000}
            onClose={handleClose}
            message={
              snackBarMessage
                ? formatMessage({ id: snackBarMessage })
                : snackBarMessageText
            }
            action={action}
            sx={{
              whiteSpace: 'pre-line',
            }}
            className={showSuccessMessage ? 'success-message' : undefined}
          />
        </div>
      </div>
    </>
  );
};

export default Admin;
