import React, { FC, ReactElement, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import { DialogFE } from '../Dialog';
import './TemplateDialog.scss';
import InputField from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  createTemplateOnServer,
  showDialog,
  updateTemplateOnServer,
} from '../../redux/uiStateActions';
import { fieldsFilled } from '../../admin/general/helpers';
import { WindowTemplateWithOptionalId } from '../../redux/uiStateReducer';
import { showSnackBar } from '../../redux/globalUiStateActions';
import { AnyAction } from 'redux';
import { MyCalcThunkDispatch } from '../../redux/store';
import { ShallowWindow } from '../../redux/projectsReducer';

interface TemplateDialogProps {
  template?: WindowTemplateWithOptionalId;
  window?: ShallowWindow;
}

function TemplateDialog(props: TemplateDialogProps): ReactElement {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const { formatMessage } = useIntl();
  const [templateName, setTemplateName] = useState(props.template?.name || '');
  const [templateDescription, setTemplateDescription] = useState(
    props.template?.description || '',
  );

  useEffect(() => {
    setTemplateName(props.template?.name || props.window?.name || '');
    setTemplateDescription(
      props.template?.description || props.window?.description || '',
    );
  }, [props.template, props.window]);

  function saveTemplate(): void {
    if (props.template?.id) {
      dispatch(
        updateTemplateOnServer({
          ...props.template,
          name: templateName,
          description: templateDescription,
        }),
      );
    } else {
      dispatch(
        createTemplateOnServer(
          templateName,
          templateDescription,
          props.window!,
        ),
      );
      dispatch(showSnackBar('TEMPLATE_CREATED_MESSAGE', undefined, false));
    }
    resetInputFields();
    dispatch(showDialog(undefined));
  }

  function mandatoryFieldsFilled(): boolean {
    return fieldsFilled(templateName);
  }

  function resetInputFields(): void {
    setTemplateDescription('');
    setTemplateName('');
  }

  return (
    <DialogFE
      heading={props.template?.id ? 'EDIT_TEMPLATE_LABEL' : 'TEMPLATE_LABEL'}
      componentClass=""
      footerProps={{
        cancelAction: true,
        saveAction: mandatoryFieldsFilled() ? saveTemplate : undefined,
        primaryActionLabel: props.template?.id
          ? 'calculations_button_save'
          : 'CREATE_PROJECT_LABEL',
      }}
    >
      <FormLayout additionalClass="template-dialog">
        <InputField
          additionalClass="template-dialog__name"
          label={formatMessage({
            id: 'NAME_OF_TEMPLATE',
          })}
          placeholder="z.B. Standard Kippfenster"
          value={templateName}
          id="templateName"
          onChange={newName => setTemplateName(newName)}
        />

        <TextArea
          inputMax={120}
          label={formatMessage({
            id: 'vorlage_description',
          })}
          additionalClass="template-dialog__description"
          placeholder="z.B. nur für Fassade geeignet"
          value={templateDescription}
          onChange={newDescription => setTemplateDescription(newDescription)}
          inputCount={true}
        />
      </FormLayout>
    </DialogFE>
  );
}

export default TemplateDialog;
