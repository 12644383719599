import React, { ErrorInfo, FC, ReactNode, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deselectDriveAndSaveAction,
  switchPage,
} from '../redux/uiStateActions';
import initializeStore, {
  IState,
  MyCalcDispatch,
  useMyCalcDispatch,
} from '../redux/store';
import './App.scss';
import '../elements/vertical-button.scss';
import '../components/Parameters/ConsoleParametersColumn.scss';
import Header from '../components/Header/Header';
import DriveDetailsColumn from '../components/Columns/DriveDetailsColumn/DriveDetailsColumn';
import CookieConsent from '../components/Footer/CookieConsent';
import { FormattedMessage, useIntl } from 'react-intl';
import ConsoleColumn from '../components/Columns/ConsoleColumn/ConsoleColumn';
import ConsoleResultsColumn from '../components/Columns/ConsoleResultsColumn/ConsoleResultsColumn';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';
import _ from 'lodash';
import ConsoleParametersColumn from '../components/Parameters/ConsoleParametersColumn';
import { ReactComponent as IconRemove } from '../IconRemove.svg';
import ConsoleDetailsColumn from '../components/Columns/ConsoleDetailsColumn/ConsoleDetailsColumn';
import ProjectsManager from '../components/Projects/ProjectsManager';
import ProjectNavigation from '../components/ProjectNavigation/ProjectNavigation';
import { Project } from '../redux/projectsReducer';
import { Pages } from '../redux/uiStateReducer';
import { DynamicSizedColumn } from '../components/Columns/DynamicSizedColumn/DynamicSizedColumn';
import intitializeAdminStore from '../redux/admin/adminStore';
import { useSelectedWindow } from '../hooks/selectorHooks';
import NRWGSolutionColumn from '../components/Columns/NRWGSolutionColumn/NRWGSolutionColumn';
import RecommendationsColumn from '../components/Columns/RecommendationsColumn/RecommendationsColumn';
import PerformanceClassColumn from '../components/Columns/PerformanceClassColumn/PerformanceClassColumn';
import TemplatesManager from '../components/Templates/TemplatesManager';
import DocumentsArea from '../components/Documents/DocumentsArea';
import { KeyCloak, qaMode } from '../keyCloak/keyCloak';
import { RequestTypes } from '../redux/httpClient';
import { Store } from 'redux';
import { Config } from '../redux/globalUiStateReducer';
import { ParametersColumn } from '../components/Parameters/ParametersColumn';
import '../logging';
import { adminView, onEnter } from '../lib/utils';
import MyCalcProvider from '../MyCalcProvider';
import Admin from '../admin/Admin';
import { useShowPrivacyPolicyEffect } from './show-privacy-policy-effect';
import { useDisplayMaintenanceWindowEffect } from './display-maintenance-window-effect';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { updateDisplaySnackBar } from '../redux/globalUiStateActions';

export let keycloak: KeyCloak;

const App: FC<React.PropsWithChildren<unknown>> = () => {
  const [store, setStore] = useState<Store>();
  useEffect(() => {
    fetchConfig().then(config => {
      const store = adminView()
        ? intitializeAdminStore(config)
        : initializeStore(config);
      setStore(store);

      window.keyCloak = new KeyCloak(store, adminView(), config.authServerUrl, {
        loginFailed: 'ERROR_MESSAGE_LOGIN_FAILED',
        noMyCalcAccess: 'ERROR_MESSAGE_NO_MYCALC_ACCESS',
        newUserWithoutCompany: 'ERROR_MESSAGE_NEW_USER_WITHOUT_COMPANY',
      });
      window.keyCloak.initKeyCloak();
    });
  }, []);

  useShowPrivacyPolicyEffect(store);

  function fetchConfig(): Promise<Config> {
    return fetch(RequestTypes.CONFIG).then(response => response.json());
  }

  if (!store) {
    return <div />;
  } else {
    return (
      <>
        <ErrorBoundary>
          <BrowserRouter>
            <Routes>
              <Route
                path="/admin/*"
                element={
                  <MyCalcProvider store={store}>
                    <Admin />
                  </MyCalcProvider>
                }
              />
              <Route
                path="*"
                element={
                  <MyCalcProvider store={store}>
                    <WrappedApp />
                  </MyCalcProvider>
                }
              />
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </>
    );
  }
};

export const WrappedApp: FC<React.PropsWithChildren<unknown>> = () => {
  const numberOfTranslations: number = useSelector(
    (state: IState) => Object.keys(state.intl.messages).length,
  );
  const activePage = useSelector<IState, number>(s => s.ui.activePage);
  const selectedProject = useSelector<IState, Project | undefined>(
    s => s.ui.selectedProject,
  );
  const alpha = useSelector<IState, boolean>(state => state.ui.alpha);
  const selectedWindow = useSelectedWindow();
  const dialog = useSelector<IState, ReactNode>(state => state.ui.dialog);

  const dispatch = useDispatch();
  const displaySnackBar = useSelector<IState, boolean>(
    state => state.globalUIState.displaySnackBar,
  );
  const snackBarMessage = useSelector<IState, string>(
    state => state.globalUIState.snackBarMessage,
  );
  const snackBarMessageText = useSelector<IState, string>(
    state => state.globalUIState.snackBarMessageText,
  );
  const showSuccessMessage = useSelector<IState, boolean>(
    state => state.globalUIState.showSuccessMessage,
  );

  const { formatMessage } = useIntl();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(updateDisplaySnackBar(false));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useDisplayMaintenanceWindowEffect(alpha, qaMode());

  function managerPageActive(): boolean {
    return (
      activePage === Pages.PROJECTS_MANAGER ||
      activePage === Pages.TEMPLATES_MANAGER ||
      activePage === Pages.DOCUMENTS_AREA
    );
  }

  if (numberOfTranslations === 0) {
    return null;
  }

  return (
    <span translate="no" className="notranslate">
      <div className="view-container">
        <CookieConsent />
        <div key="App" className="app">
          {selectedProject && <ProjectNavigation />}
          <Header />

          {!managerPageActive() && (
            <>
              <Page index={Pages.CALCULATION}>
                {(!selectedProject || selectedWindow !== undefined) && (
                  <>
                    <ParametersColumn />
                    <DynamicSizedColumn />
                    {selectedWindow?.nrwg && <PerformanceClassColumn />}
                    <RecommendationsColumn />
                    <DriveDetailsColumn page={Pages.CALCULATION} />
                  </>
                )}
              </Page>
              {!selectedWindow?.nrwg ? (
                <Page index={Pages.SELECTED_PRODUCTS} transition={true}>
                  <ReleaseDriveColumn />
                  <DriveDetailsColumn page={Pages.SELECTED_PRODUCTS} />
                  <ConsoleColumn />
                </Page>
              ) : (
                <Page index={Pages.SELECTED_PRODUCTS_NRWG} transition={true}>
                  {!selectedWindow.locked && <ReleaseDriveColumn />}
                  <NRWGSolutionColumn />
                  <DriveDetailsColumn page={Pages.SELECTED_PRODUCTS} />
                </Page>
              )}
              <Page index={Pages.CONSOLE_CALCULATION} transition={true}>
                <BackColumn />
                <ConsoleParametersColumn />
                <ConsoleResultsColumn />
                <ConsoleDetailsColumn />
              </Page>
            </>
          )}
          {activePage === Pages.DOCUMENTS_AREA && (
            <Page index={Pages.DOCUMENTS_AREA}>
              <DocumentsArea />
            </Page>
          )}
          {activePage === Pages.PROJECTS_MANAGER && (
            <Page index={Pages.PROJECTS_MANAGER}>
              <ProjectsManager />
            </Page>
          )}
          {activePage === Pages.TEMPLATES_MANAGER && (
            <Page index={Pages.TEMPLATES_MANAGER}>
              <TemplatesManager />
            </Page>
          )}
        </div>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={displaySnackBar}
          autoHideDuration={4000}
          onClose={handleClose}
          message={
            snackBarMessage
              ? formatMessage({ id: snackBarMessage })
              : snackBarMessageText
          }
          action={action}
          sx={{
            whiteSpace: 'pre-line',
          }}
          className={showSuccessMessage ? 'success-message' : undefined}
        />
      </div>
      {dialog}
    </span>
  );
};

const BackColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch() as MyCalcDispatch;

  return (
    <div
      className="vertical-button vertical-button--back"
      onClick={() => dispatch(switchPage(Pages.SELECTED_PRODUCTS))}
    >
      {' '}
      <div className="vertical-button__title">
        <FormattedMessage id="DESELECT_SELECTION" />
      </div>
      <div className="vertical-button__icon-container">
        <IconRemove />
      </div>
    </div>
  );
};

const ReleaseDriveColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useMyCalcDispatch();

  function save() {
    dispatch(deselectDriveAndSaveAction());
  }

  return (
    <div
      className="vertical-button vertical-button--back vertical-button--release vertical-button--separator"
      onClick={save}
      onKeyDown={onEnter(save)}
      tabIndex={0}
    >
      <div className="vertical-button__title">
        <FormattedMessage id="DESELECT_SELECTION" />{' '}
      </div>
      <div className="vertical-button__icon-container">
        <IconRemove />
      </div>
    </div>
  );
};

interface PageProps {
  children: ReactNode;
  index: Pages;
  transition?: boolean;
}

const Page: FC<React.PropsWithChildren<PageProps>> = (props: PageProps) => {
  const activePage = useSelector<IState, number>(
    (s: IState) => s.ui.activePage,
  );
  const selectedWindow = useSelectedWindow();

  if (!props.transition) {
    return (
      <ErrorBoundary>
        <div
          className={classNames(
            'app__page',
            `app__page--${_.kebabCase(Pages[props.index])}`,
            `app__page--stack-position-${props.index}`,
            { 'app__page--nrwg-locked': selectedWindow?.locked },
          )}
        >
          {props.children}
        </div>
      </ErrorBoundary>
    );
  }

  return (
    <Transition
      in={props.index <= activePage}
      timeout={500}
      appear={props.index === Pages.CALCULATION}
    >
      {state => {
        return (
          <div
            className={classNames(
              'app__page',
              `app__page--${_.kebabCase(Pages[props.index])}`,
              `app__page--${state}`,
              `app__page--stack-position-${props.index}`,
              { 'app__page--nrwg-locked': selectedWindow?.locked },
            )}
          >
            {state !== 'exited' && props.children}
          </div>
        );
      }}
    </Transition>
  );
};

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  { children: ReactNode },
  ErrorBoundaryState
> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfoL: ErrorInfo): void {
    window.logger.error('ErrorBoundary caught error');
    window.logger.error(error.stack);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <div />;
    }

    return this.props.children;
  }
}

export default App;
