import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IState } from '../store';
import { postAndStore, RequestTypes } from '../httpClient';
import { getProjects } from '../projectsActions';
import { getDocumentsListsResult } from '../staticDataActions';
import { getTemplates, storeUser, StoreUserAction } from '../uiStateActions';
import { showSnackBar } from '../globalUiStateActions';
import { adminView } from '../../lib/utils';
import { LoggedInUser } from '../authenticationReducer';

export interface SetUserIsAuthenticated
  extends Action<'SET_USER_IS_AUTHENTICATED'> {
  userIsAuthenticated: boolean | undefined;
}

export interface SetUserIsAuthorized extends Action<'SET_USER_IS_AUTHORIZED'> {
  userIsAuthorized: boolean;
}

export function setUserIsAuthenticated(
  authStatus: boolean,
): SetUserIsAuthenticated {
  return { type: 'SET_USER_IS_AUTHENTICATED', userIsAuthenticated: authStatus };
}

export function setUserIsAuthorized(authStatus: boolean): SetUserIsAuthorized {
  return { type: 'SET_USER_IS_AUTHORIZED', userIsAuthorized: authStatus };
}

export function loginSSO(
  token: string,
  loginError: string,
): ThunkAction<Promise<void>, IState, void, StoreUserAction> {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      StoreUserAction | SetUserIsAuthenticated | SetUserIsAuthorized
    >,
  ): Promise<void> => {
    await postAndStore(
      RequestTypes.LOGIN,
      async (user: LoggedInUser | undefined) => {
        if (user) {
          // if (!qaMode()) {
          window.keyCloak?.loginSuccessful();
          // }

          window.user_id = user.id;
          dispatch(setUserIsAuthorized(true));
          dispatch(storeUser(user!));
          if (!adminView()) {
            dispatch(getProjects(0));
            dispatch(getTemplates());
            dispatch(getDocumentsListsResult());
          }
        }
      },
      { token: token },
      999,
      undefined,
      undefined,
      () => {
        dispatch(setUserIsAuthenticated(false));
        dispatch(setUserIsAuthorized(false));
        dispatch(showSnackBar(loginError, undefined, false));
      },
    );
  };
}

export type keyCloakActions = SetUserIsAuthenticated;
